import {Toast, Dialog, setToastDefaultOptions} from 'vant';
import 'vant/lib/toast/style';
import 'vant/lib/dialog/style';

setToastDefaultOptions('loading', {
    forbidClick: true,
    duration: 0,
});

export const installVant = (app) => {
    app.use(Toast);
    app.use(Dialog);
}
