import p1 from "@/assets/image/47.png"
import p2 from "@/assets/image/48.png"
import p3 from "@/assets/image/49.png"
import p1_a from "@/assets/image/47_a.png"
import p2_a from "@/assets/image/48_a.png"
import p3_a from "@/assets/image/49_a.png"

export const site_config = {
    title: import.meta.env.VITE_APP_TITLE,
    api: import.meta.env.DEV ? import.meta.env.VITE_APP_API_HOST : import.meta.env.VITE_APP_API_HOST,
}
export const ext_config = {
    file: ".pdf,.ppt,.doc,.docx,.word,.xlxs,.xls",
    image: ".jpg,.jpeg,.png,.gif,.svg,.tiff",
    video: ".mp4,.mov,.ogg",
}

export const task_idea = [
    {id: 1, x: 140, y: 520, title: "共创音乐热爱", min: "刘宪华邀你共创潮趣音乐，\n体会新奢音乐人生", desc: "Step1:进行vr音乐游戏互动\nStep2：获取 xx积分即为过关", point: p1, point_a: p1_a},
    {id: 2, x: 330, y: 160, title: "灵感同框", min: "smart idea为你提供灵感\n码住新花样，邀你AI无限", desc: "Step1:请前往smart idea选取您的灵感单品\nStep2:拍摄您的今日灵感OOTD\nStep3:扫码获取您的AI剧照", tip: "（小提示：将AI剧照转发到朋友圈，更有机会抽取大礼）", point: p2, point_a: p2_a},
    {id: 3, x: 455, y: 160, title: "灵感收集器", min: "释放你的灵感\n换取smart专属热爱\n享受灵感迸发的快乐", desc: "Step1:站上舞台讲出你想对smart说的话\nStep2:你的灵感金句将在车展现场展示", point: p3, point_a: p3_a},
]
