import {auth, views} from "@/api/index.js";
import {site_config} from "@/config/index.js";
import { getUrlParams} from "@/common/tool.js";
import {viewsStore} from "@/store/index.js";

export const doViewsLogin = async() => {
	return new Promise(async (resolve, reject) => {
		const store = viewsStore()
		const token = store.token;
		if (!token) {
			const uuid = getUrlParams('userid');
			if(!uuid){
				reject()
			}else{
				try {
					const res = await auth.login({
						source:1,
						uuid: uuid,
                        page:getUrlParams('page') || '',
                        channel:getUrlParams('channel') || '',
					})
					store.setToken(res?.token || '');
					store.setUserInfo(res?.user_info || {});
					resolve();
				}catch(err){
					reject(err)
				}
			}
		} else {
			resolve(token);
		}
	})
}

export const createViewLog = (params = {})=>{
	doViewsLogin().then((res)=>{
		const data = Object.assign({},{
			source: 1,
			title: document.title,
			ua: navigator.userAgent,
			url: window.location.href,
		},params);
		views.log(data).then(()=>{})
	})
}

export const setTitle = (title )=>{
	document.title = title || site_config.title;
}
