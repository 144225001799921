import axios from 'axios'
import {site_config} from "@/config/index.js"
import {getCookie, isDev, setCookie} from "@/common/tool";
import {viewsStore} from "@/store/index.js";

const requestInstance = axios.create({
    timeout: 60 * 1000,
})

requestInstance.interceptors.request.use(config => {
    const store = viewsStore()
    config.baseURL = isDev() ? '/proxy' : site_config.api;
    config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
    config.headers['Token'] = store.token || '';
    return config
}, error => {
    return Promise.reject(error)
})

requestInstance.interceptors.response.use((response) => {
        let message;
        let res = response.data;
        switch (res.code) {
            case 1:
                return Promise.resolve(res.data);
            default:
                message = `${res.msg || 'Error'}`
                return Promise.reject({code: res.code, message: message});
        }
    },
    async (error) => {
        let message = error.message;
        let response = "";
        if (error && error.response) {
            response = error.response;
            let status = response.status;
            let statusText = response.statusText;
            let path = "";
            if (error.response.config) {
                path = response.config.baseURL + response.config.url;
            }
            switch (status) {
                case 403:
                    setCookie('token', '')
                    break;
                case 404:
                    message = response?.data?.msg || error.message
                    break;
                case 500:
                    message = `${statusText} : ${path}`;
                    break;
                default:
                    message = `温馨提示：您的网络目前正在堵车`
                    break;
            }
        }
        return Promise.reject({code: response.status, message: message})
    }
)

export const request = requestInstance;
