import {getCookie} from "@/common/tool.js";

export default [
    {
        path: "/admin",
        component: () => import('@/layout/admin/Admin.vue'),
        children: [
            {
                path: '/admin',
                component: () => import('@/pages/admin/dashboard/Index.vue'),
                meta: {title: '控制台'},
            },
            {
                path: '/admin/article',
                component: () => import('@/pages/admin/article/Index.vue'),
                meta: {title: '文章管理'},
            },
            {
                path: '/admin/lottery/prize',
                component: () => import('@/pages/admin/lottery/prize/Index.vue'),
                meta: {title: '奖品管理'},
            },
            {
                path: '/admin/lottery/log',
                component: () => import('@/pages/admin/lottery/log/Index.vue'),
                meta: {title: '抽奖日志'},
            },
            {
                path: '/admin/check_code',
                component: () => import('@/pages/admin/checkCode/Index.vue'),
                meta: {title: '核销码管理'},
            },
            {
                path: '/admin/task/qrcode',
                component: () => import('@/pages/admin/task/qrcode/Index.vue'),
                meta: {title: '任务二维码'},
            },
            {
                path: '/admin/task/log',
                component: () => import('@/pages/admin/task/log/Index.vue'),
                meta: {title: '任务日志'},
            },
            {
                path: '/admin/paper/question',
                component: () => import('@/pages/admin/paper/question/Index.vue'),
                meta: {title: '问卷管理'},
            },
            {
                path: '/admin/paper/log',
                component: () => import('@/pages/admin/paper/log/Index.vue'),
                meta: {title: '问卷日志'},
            },
            {
                path: '/admin/user',
                component: () => import('@/pages/admin/user/Index.vue'),
                meta: {title: '用户管理'},
            },
            {
                path: '/admin/admin',
                component: () => import('@/pages/admin/admin/Index.vue'),
                meta: {title: '管理员'},
            },
            {
                path: '/admin/config',
                component: () => import('@/pages/admin/config/Index.vue'),
                meta: {title: '配置管理'},
            },
            {
                path: '/admin/config/config',
                component: () => import('@/pages/admin/config/Config.vue'),
                meta: {title: '系统配置'},
            },
            {
                path: '/admin/:pathMatch(.*)*',
                name: 'Error',
                component: () => import('@/pages/admin/Error.vue'),
                meta: {title: '错误'},
            }
        ],
        beforeEnter: (to, from, next) => {
            if (!getCookie('authorization')) {
                next('/admin/auth/login')
            } else {
                next();
            }
        }
    },
    {
        path: '/admin/auth/login',
        component: () => import('@/pages/admin/auth/Login.vue'),
        meta: {title: '登录'},
    },
]
