import {defineStore} from 'pinia'
import {random} from "lodash";

const randomPosition = (data) => {
    const position_list = [];
    data.map((item, index) => {
        position_list.push({
            x: item.x,
            y: item.y,
        })
    })
    data.map((item) => {
        const index = random(0, position_list.length - 1);
        const one = position_list[index];
        item.x = one.x;
        item.y = one.y;
        position_list.splice(index, 1)
    })
    return data;
}

export const viewsStore = defineStore('views', {
    state: () => {
        return {
            config: "",
            token: "",
            user_info: {},
            task_idea: [],
        }
    },
    getters: {},
    actions: {
        setToken(token) {
            this.token = token;
        },
        setUserInfo(user_info) {
            this.user_info = user_info;
        },
        setConfig(config) {
            this.config = config;
        },
        setTaskIdea(task_idea) {
            this.task_idea = task_idea;
        },
    }
})
