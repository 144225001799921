import {doViewsLogin} from "@/common/page.js";
import {viewsStore} from "@/store/index.js";

export default [
    {
        path: "/",
        component: () => import('@/layout/Pages.vue'),
        children: [
            {
                path: '/',
                component: () => import('@/pages/Index.vue'),
                meta: {title: '首页'},
            },
            {
                path: '/welcome',
                component: () => import('@/pages/Welcome.vue'),
                meta: {title: '欢迎'},
            },
            {
                path: '/lottery',
                component: () => import('@/pages/Lottery.vue'),
                meta: {title: '抽奖'},
            },
            {
                path: '/code',
                component: () => import('@/pages/Code.vue'),
                meta: {title: '核销'},
            },
            {
                path: '/book',
                component: () => import('@/pages/Book.vue'),
                meta: {title: '核销'},
            },
            {
                path: '/map',
                component: () => import('@/pages/Map.vue'),
                meta: {title: '灵感剧场地图'},
            },
            {
                path: '/step',
                component: () => import('@/pages/Step.vue'),
                meta: {title: '灵感剧场关卡'},
            },
            {
                path: '/theater',
                component: () => import('@/pages/Theater.vue'),
                meta: {title: '开启灵感剧场'},
            },
            {
                path: '/share',
                component: () => import('@/pages/Share.vue'),
                meta: {title: '分享'},
            },
            {
                path: '/end',
                component: () => import('@/pages/End.vue'),
                meta: {title: '结束'},
            },
        ],
        beforeEnter: (to, from, next) => {
            const store = viewsStore()
            doViewsLogin().then(() => {
                const user_info = store.user_info;
                //const path = user_info.step <= 2 ? '/' : user_info.step === 5 ? '/map' : '/theater';
                const path = '/'
                if (to.path === path) {
                    next();
                } else {
                    next(path)
                }
            }).catch(() => {
                //next('/error?code=500&message=请先登录')
            })
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: () => import('@/pages/Error.vue'),
        meta: {title: '错误'},
    }
]
